<template lang="pug">
.lot-actions(:class="isFastSearch && 'column-view'")
  template(v-if="isFastSearch && isAuth && enableLotManagement && (!isTmManager || managerCompanyId)")
    ui-button.green-button.center.full-width(v-if="!roleReadOnly" type="secondary" :loader="appStatusLoader" :icon-right="lot.app ? UiIconNames.Icon_StarO : ''" @click="createApp") {{ lot.app ? 'Заявка создана' : 'Добавить в избранное' }}
    ui-button.center.full-width(v-else-if="lot.app" disabled type="link" :icon-right="lot.app ? UiIconNames.Icon_StarO : ''") Заявка создана
  ui-button.orange-button(v-if="!isTmManager && !isFastSearch" type="secondary" @click="openTenderService(lot)") Заказать тендерные услуги
  lot-link(v-if="![Tabs.LotDetail, Tabs.LotGovDetail].includes(routeName)" :id="lot?.lotId")
    icon-button(v-if="isTmManager" as-button :icon="UiIconNames.Icon_More" tooltip-title="Подробней о закупке")
    ui-button.center(v-else :class="isFastSearch && 'full-width'" :type="isFastSearch ? 'tertiary' : 'primary'") Подробней о закупке
  a(v-if="lot.link && !isFastSearch" :href="lot.link" target="_blank")
    icon-button(
      as-button
      button-type="secondary"
      tooltip-title="Открыть карту лота на площадке размещения"
      :icon="UiIconNames.Icon_Link"
    )
  icon-button(
    v-if="byTemplate && showUnseenButton && !isTmManager"
    as-button
    button-type="secondary"
    :icon="lot?.IsSeen ? UiIconNames.Icon_CheckUnderline : UiIconNames.Icon_EyeOpen"
    :tooltip-title="lot?.IsSeen ? 'Лот просмотрен' : 'Отметить лот просмотренным'"
    @click="$emit('markViewed')"
  )
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";
import { useTenderService } from "~/use/tenderService/useTenderService";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useSuperAccount } from "~/use/superAccount/useSuperAccount";
import { useAppModifier } from "~/components/pages/applications/list/useAppModifier";

import UiButton from "~/components/ui/button/UiButton.vue";
import IconButton from "~/components/ui/button/IconButton.vue";
import LotLink from "~/components/ui/links/LotLink.vue";

import type { PropType } from 'vue';
import type { LotItemInterface } from "~/stores/search/LotItemInterface";

import UiIconNames from "@/components/ui/icon/UiIconNames";
import { Tabs } from "@/router/tabs";
import { AppStatusFavourite } from "~/const/statuses";
import useManualsStore from "~/stores/manuals/useManualsStore";
import useNotificationsStore from "~/stores/systemNotifications/useNotificationsStore";

export default defineComponent({
  name: "LotActions",
  components: {
    LotLink,
    UiButton,
    IconButton,
  },
  emits: [
    'markViewed',
  ],
  props: {
    lot: {
      type: Object as PropType<LotItemInterface>,
      default: () => ({}),
    },
    byTemplate: {
      type: Boolean,
      default: false,
    },
    showUnseenButton: {
      type: Boolean,
      default: false,
    },
    isFastSearch: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {

    const route = useRoute();

    const { showSuccess } = useNotificationsStore();
    const { openTenderService } = useTenderService();
    const { isTmManager, isAuth, enableLotManagement, roleReadOnly } = useUserAccess();
    const { managerCompanyId } = useSuperAccount();

    const { createNewApp } = useAppModifier();

    const manualsStore = useManualsStore()
    const { appStatuses } = storeToRefs(manualsStore)

    const appStatusLoader = ref(false);

    function createApp() {
      if (props.lot?.app) showSuccess('Заявка уже создана. Перейдите на вкладку "Заявки" для просмотра');
      else {
        appStatusLoader.value = true
        createNewApp(props.lot, appStatuses.value.find(e => e.id === AppStatusFavourite), true, true)
          .catch(() => {})
          .finally(() => appStatusLoader.value = false)
      }
    }

    return {
      routeName: route.name as string,
      isTmManager,
      isAuth,
      roleReadOnly,
      enableLotManagement,
      managerCompanyId,
      appStatusLoader,
      UiIconNames,
      createApp,
      openTenderService,
      Tabs,
    }
  }
})
</script>

<style scoped lang="scss">
.lot-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;

  &.column-view {
    flex-flow: column;
    margin-top: auto;
  }
}

.center {
  justify-content: center;
}

.orange-button {
  color: #ed7a1d !important;
  border: 1px solid #FFC310 !important;

  &:hover {
    background-color: #fff6ec !important;
  }
}

.green-button {
  color: #67c23a !important;
  border: 1px solid #b3e19d !important;

  &:hover {
    background-color: #f0f9eb !important;
  }
}

.full-width {
  width: 100%;
}
</style>
